<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card pb-3" :loading="loading" min-height="150px">
        <v-toolbar flat dense
          ><h3>List Applicant</h3>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            solo
            dense
            rounded
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @keyup="fetchItems"
          ></v-text-field>
        </v-toolbar>

        <div v-if="applicant">
          <v-data-table
            :headers="headers"
            :items="applicant.data"
            :item-class="isClosed"
            hide-default-footer
            :page.sync="page"
            :items-per-page="perPage"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              {{ $date(item.created_at).format("DD, MMMM, YYYY") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="green"
                  class="mr-2"
                  content="View Profile"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  :to="`/applicant/${item.user_id}`"
                >
                  <v-icon dark small> mdi-eye </v-icon>
                </v-btn>
                <v-btn
                  small
                  color="primary"
                  dark
                  @click="openDialogStatus(item)"
                >
                  {{ item.status }}
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="white"
                  content="open thread"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  @click.prevent="closeItem(item)"
                  v-if="item.status == 'closed'"
                >
                  <v-icon dark small> mdi-lock-open </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pa-3">
            <v-pagination
              v-model="page"
              circle
              @input="fetchItems"
              :length="applicant.meta.last_page"
            ></v-pagination>
          </div>
        </div>
      </v-card>
    </div>
    <div class="pb-1">
      <CloseThread
        v-bind:closeThread="closeThread"
        v-bind:item="item"
        @close="closeDialog"
      />
    </div>
    <DialogStatus
      v-bind:indexStatus="indexStatus"
      v-bind:dataStatus="dataStatus"
      v-bind:dialogStatus="dialogStatus"
      @close="closeDialogStatus"
      @refetch="fetchItems"
    ></DialogStatus>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import CloseThread from "../../components/Admin/Modal/closeThread.vue";
import DialogStatus from "../../components/Psycholog/Modal/editStatusApplicant.vue";

export default {
  name: "ApplicantManagement",
  components: { CloseThread, DialogStatus },

  computed: {
    ...mapState({
      applicant: state => state.vacancies.applicant,
      env: state => state.API_URL
    })
  },

  data() {
    return {
      dialogAdd: false,
      tab: null,
      dialogUpdate: false,
      dialogMod: false,
      item: null,
      closeThread: false,
      search: "",
      loading: false,
      dialogStatus: false,
      dataStatus: null,
      indexStatus: 0,
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "NAME", value: "nama_lengkap", sortable: false },
        { text: "CATEGORY", value: "nama_kategori", sortable: false },
        { text: "COMPANY", value: "perusahaan", sortable: false },
        {
          text: "CREATED AT",
          value: `created_at`,
          sortable: false
        },
        { text: "ACTIONS", value: "actions", sortable: false }
      ],
      page: 1,
      perPage: 10
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      //get all data  fucntion
      this.loading = true;
      this.$store
        .dispatch("vacancies/listApplicant", {
          page: this.page,
          per_page: this.perPage,
          search: this.search,
          limit: this.perPage
        })
        .then(() => {
          this.loading = false;
        });
    },

    isClosed: function(item) {
      if (item.status == "closed") {
        return "red darken-1 white--text";
      }
    },

    closeItem(item) {
      this.item = item;
      this.closeThread = true;
    },

    assignModerator(item) {
      this.item = item;
      this.dialogMod = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("forum/deleteForum", { id: item.id })
            .then(() => {
              this.fetchItems(); //if success refetch data all
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.closeThread = false;
      this.fetchItems(); //and refetch data
    },

    openDialogStatus(data) {
      this.dataStatus = data;
      this.dialogStatus = true;
      if (data.status == "accepted") {
        this.indexStatus = 1;
      } else if (data.status == "rejected") {
        this.indexStatus = 2;
      } else {
        this.indexStatus = 0;
      }
    },

    closeDialogStatus() {
      this.dataStatus = null;
      this.dialogStatus = false;
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
