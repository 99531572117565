<template>
  <v-row justify="start">
    <v-dialog v-model="closeThread" persistent max-width="400px">
      <v-card class="radius-card" v-if="item">
        <v-toolbar dense flat>
          <span class="headline" v-if="item.status == 'open'">Why?</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="closeDialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container v-if="item.status == 'open'">
            <div v-for="(item, idx) in message" :key="idx">
              <v-card
                :color="selectedId == item.id ? 'green' : ''"
                class="pa-2 radius-card my-2"
                @click="select(item)"
              >
                <p :class="selectedId == item.id ? 'white--text ma-0' : 'ma-0'">
                  {{ item.msg }}
                </p>
              </v-card>
            </div>
          </v-container>
          <v-container v-if="item.status == 'closed'">
            <h1>Are you sure?</h1>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            outlined
            rounded
            v-if="selected && item.status == 'open'"
            @click="close"
            >Close thread!</v-btn
          >
          <v-btn
            color="blue"
            outlined
            rounded
            v-if="item.status == 'closed'"
            @click="close"
            >Open thread!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "lockthread",

  props: ["closeThread", "item"],

  computed: {
    ...mapState({
      province: state => state.area.province
    })
  },
  data() {
    return {
      selected: null,
      selectedId: null,
      message: [
        {
          id: 1,
          name: "Thread dinilai sudah tidak relevan namun terangkat kembali",
          msg: "Thread dinilai sudah tidak relevan namun terangkat kembali"
        },
        {
          id: 2,
          name: "sara",
          msg:
            "Thread mengandug Pornografi, Rasisme, dan konten yang tidak pantas lainya."
        }
      ]
    };
  },
  mounted() {},
  methods: {
    select(item) {
      this.selectedId = item.id;
      this.selected = item;
    },
    close() {
      let data = {
        id_forum: this.item.id,
        msg: this.selected ? this.selected.name : ""
      };
      this.$store
        .dispatch("forum/closeThread", data)
        .then(data => {
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          this.$emit("close");
        })
        .catch(err => {
          Swal.fire({
            title: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        });
    },
    closeDialog() {
      this.selected = null;
      this.selectedId = null;
      this.$emit("close");
    }
  }
};
</script>

<style></style>
