<template>
  <v-row justify="start">
    <v-dialog v-model="dialogStatus" persistent max-width="300px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Status</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="mx-auto" max-width="300" tile elevation="0">
          <v-list dense>
            <v-list-item-group v-model="indexStatus" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="editStatus(dataStatus)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "editStatusApplicant",
  props: ["dataStatus", "dialogStatus", "indexStatus"],
  data: () => ({
    items: [
      { text: "Waiting", icon: "mdi-clock" },
      { text: "Accepted", icon: "mdi-check-bold" },
      { text: "Rejected", icon: "mdi-close" }
    ]
  }),
  computed: {
    ...mapState({
      //   profile: (state) => state.psycholog.myProfile,
    })
  },
  mounted() {
    //
  },
  methods: {
    editStatus(dataStatus) {
      let data = new FormData();
      if (this.indexStatus == 1) {
        data.append("status", "accepted");
      } else if (this.indexStatus == 2) {
        data.append("status", "rejected");
      } else {
        data.append("status", "waiting");
      }
      let id = dataStatus.id;
      this.$store
        .dispatch("psycholog/editStatusApplicant", { id: id, data: data })
        .then(data => {
          console.log(data);
          this.$emit("refetch");
          this.$emit("close");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        })
        .catch(err => {
          for (let i in err) {
            this.alert("error", `${err[i][0]}`);
          }
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
